import { Responsiveness, responsiveness } from './static/responsiveness';
import { header } from './static/header/header';
import { sidebar } from './static/sidebar';

console.log("stats_leaders")
responsiveness.init();

// Listen for future changes in responsiveness breakpoint
matchMedia(responsiveness.mq).addListener(function(e){Responsiveness.checkBreakpoint(e,responsiveness)});

header.attachHandlers();
sidebar.attachHandlers();
