import { links } from './links';
import { filters } from './filters';

class Header {

  attachHandlers() {
    links.attachHandlers();
    filters.attachHandlers();
  };

}

export let header = new Header();
