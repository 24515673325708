class Filters {

  attachHandlers() {

    $('.stats_leaders').on('click', '#filters--update', function(e) {
      $('.filters-container .filters--field').each(function() {
        if(!$(this).val()) {
          $(this).attr('disabled', true);
        }
      });
    });

  };

}

export let filters = new Filters();