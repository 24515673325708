import $ from "jquery";

export class Rank {

  static checkBreakpoint(e, rank_object) {
    // switch to mobile functionality
    if (e.matches) {
      rank_object.initMobile();
      // switch to non-mobile functionality
    } else {
      rank_object.initDesktop();
    }
  };

  static toggleAddFighters(e, rank_object) {
    if (rank_object.addFightersVisible) {
      document.body.classList.remove('has-overlay');
      rank_object.addFightersVisible = false;
    } else {
      document.body.classList.add('has-overlay');
      rank_object.addFightersVisible = true;

      // give focus to now-visible overlay
      rank_object.addFighters.focus();
    }

    // update semantics on relevant elements
    rank_object.addFightersOpen.setAttribute('aria-expanded', rank_object.addFightersVisible);
    rank_object.addFightersOpenIcon.setAttribute('aria-expanded', rank_object.addFightersVisible);
    rank_object.addFightersClose.setAttribute('aria-expanded', rank_object.addFightersVisible);
    rank_object.addFightersCloseIcon.setAttribute('aria-expanded', rank_object.addFightersVisible);
    rank_object.addFighters.setAttribute('aria-hidden', rank_object.addFightersVisible ? false : true);
  };

  initMobile() {
    // check if we're moving from desktop, if so undo desktop-specific stuff
    if (this.siteMode == 'desktop') {
      $("#ranking-list").sortable("destroy");
      $("#fighter-select-list").sortable("destroy");
      $("#temp-list").sortable("destroy");
    }

    // add hidden class to main navigation
    this.menuMain.classList.add('hidden');

    // move 'add fighters' module for mobile
    document.body.appendChild(this.addFighters);

    // add open/close semantics for relevant elements
    this.addFightersOpen.setAttribute('aria-expanded', 'false');
    this.addFightersOpenIcon.setAttribute('aria-expanded', 'false');
    this.addFightersClose.setAttribute('aria-expanded', 'false');
    this.addFightersCloseIcon.setAttribute('aria-expanded', 'false');
    this.addFightersOpen.setAttribute('aria-controls', this.addFighters.id);
    this.addFightersOpenIcon.setAttribute('aria-controls', this.addFighters.id);
    this.addFightersClose.setAttribute('aria-controls', this.addFighters.id);
    this.addFightersCloseIcon.setAttribute('aria-controls', this.addFighters.id);
    this.addFighters.setAttribute('aria-hidden', 'true');
    this.addFighters.setAttribute('tabindex', '0');
    $("#add-fighters-open").off('click').on("click", function (e) {
      Rank.toggleAddFighters(e, rank);
    })
    $("#add-fighters-open-icon").off('click').on("click", function (e) {
      Rank.toggleAddFighters(e, rank);
    })
    $("#add-fighters-close").off('click').on("click", function (e) {
      Rank.toggleAddFighters(e, rank);
    })
    $("#add-fighters-close-icon").off('click').on("click", function (e) {
      Rank.toggleAddFighters(e, rank);
    })


    //set site mode variable
    this.siteMode = 'mobile';
  }

  initDesktop() {
    // check if we're moving from mobile, if so then undo mobile-specific things
    if (this.siteMode == 'mobile') {
      // remove hidden class from main navigation
      this.menuMain.classList.remove('hidden');

      // move 'add fighters' section back to desktop spot
      this.rankingsContentWrapper.prepend(this.addFighters);

      // remove open/close semantics for relevant elements
      this.addFightersOpen.removeAttribute('aria-expanded');
      this.addFightersOpenIcon.removeAttribute('aria-expanded');
      this.addFightersClose.removeAttribute('aria-expanded');
      this.addFightersCloseIcon.removeAttribute('aria-expanded');
      this.addFightersOpen.removeAttribute('aria-controls');
      this.addFightersOpenIcon.removeAttribute('aria-controls');
      this.addFightersClose.removeAttribute('aria-controls');
      this.addFightersCloseIcon.removeAttribute('aria-controls');
      this.addFighters.removeAttribute('aria-hidden');
      this.addFighters.removeAttribute('tabindex');
    }
    this.initSorting();


    //set site mode variable
    this.siteMode = 'desktop';
  }

  init() {
    this.showTrendingFighters();

    this.step2 = null
    let rank = this
    this.weightClass = $('#weightClass').val();
    this.weightClassText = $('#weightClassText').val();
    this.fighters = JSON.parse($('#fightersRaw').val());
    this.image_text = JSON.parse($('#image_text').val());
    this.canvasImg16 = $('#canvas16').val();
    this.canvasImg16noChamp = $('#canvas16noChamp').val();
    this.canvasImg162Champ = $('#canvas162Champ').val();
    this.canvasImg9 = $('#canvas9').val();
    this.canvasImg9noChamp = $('#canvas9noChamp').val();
    this.canvasImg92Champ = $('#canvas92Champ').val();
    this.canvasImg1 = $('#canvas1').val();
    this.canvasImg1noChamp = $('#canvas1noChamp').val();
    this.canvasImg12Champ = $('#canvas12Champ').val();
    this.silhouette = $('#silhouette').val();
    this.champion = JSON.parse($('#champion').val());
    this.fighters = JSON.parse($('#fightersRaw').val());

    this.gold = '#CCAE5B';
    this.grey = '#888891';
    this.black = '#000000';
    this.white = '#FFFFFF';

    this.hasChampion = false
    this.hasInterimChamp = false;

    $("#ranking-list li").each(function () {
      if ($(this).data("fighter-rank") == 0) {
        rank.hasInterimChamp = true;
      }
    });


    this.mq = `(max-width: 768px)`;
    this.maxFighters =  15
    // get elements
    this.mobileNavToggle = document.querySelector('#menu-main--mobile-menu-toggle');
    this.menuMain = document.querySelector('#menu-main');
    this.rankingsContentWrapper = document.querySelector('.rankings-content--wrapper');
    this.addFighters = document.querySelector('#add-fighters');
    this.addFightersOpen = document.querySelector('#add-fighters-open');
    this.addFightersOpenIcon = document.querySelector('#add-fighters-open-icon');
    this.addFightersClose = document.querySelector('#add-fighters-close');
    this.addFightersCloseIcon = document.querySelector('#add-fighters-close-icon');
    this.yourRankings = document.querySelector('#your-rankings');

    this.rankOrder = []
    this.fightersAllButton = document.querySelector('#sort-button--all');

    this.fightersAllButton.addEventListener('click', function (e) {
      rank.showAllFighters();
    });
    this.fightersTrendingButton = document.querySelector('#sort-button--trending');
    this.fightersTrendingButton.addEventListener('click', function (e) {
      rank.showTrendingFighters();
    });
    $("#button-next-to-share").off('click').on("click", function (e) {
      e.preventDefault();
      rank.processShareButton();

    });
    if (this.weightClass == "mp4p" || this.weightClass == "wp4p") {
      $("#sort-button--all").hide()
    }

    this.initButtons();


    // check breakpoint initially
    if (window.matchMedia(this.mq).matches) {
      this.siteMode = 'mobile';
      this.initMobile();
    } else {
      this.siteMode = 'desktop';
      this.initDesktop();
    }

    // setup mobile navigation
    $("#menu-main--mobile-menu-toggle").unbind("click");
    this.mobileNavToggle.addEventListener('click', (e) => {
      if (e.currentTarget.getAttribute('aria-expanded') == 'true') {
        e.currentTarget.setAttribute('aria-expanded', 'false');
        this.menuMain.classList.add('hidden');
      } else {
        e.currentTarget.setAttribute('aria-expanded', 'true');
        this.menuMain.classList.remove('hidden');
      }
    });
  }

  removeAddFightersFromSelectionList(fighter, remove) {
    let fighterObj = {}
    if (fighter) {
      fighterObj.first_name = $(fighter).data("fighter-first-name");
      fighterObj.last_name = $(fighter).data("fighter-last-name");
      fighterObj.id = parseInt($(fighter).data("fighter-id").toString().trim());
      fighterObj.img = $(fighter).data("fighter-img");
      fighterObj.trending = $(fighter).data("fighter-trending");
      fighterObj.rank = null

    }
    if (!remove) {
      rank.fighters.push(fighterObj)
    } else {
      let BreakException = {}
      try {
        rank.fighters.forEach((el) => {
          if (el.id === fighterObj.id) {
            rank.fighters.splice(el, 1);
            throw BreakException
          }
        });
      } catch (e) {
        if (e !== BreakException) throw e;
      }
    }
  }

  processShareButton() {
    let champ = 1;
    if (rank.hasChampion) {
      champ = 0
    }
    if ($("#ranking-list li").length < 6 - champ) {
      $("#errorMessage").show();
    } else {
      rank.renderSharing();
    }
  }


  initButtons() {
    $(".move-up").off('click').on("click", function (e) {
      e.preventDefault();
      let elem = $(this).parent().parent()
      rank.moveFighterUp(elem);
    });
    $(".move-down").off('click').on("click", function (e) {
      e.preventDefault();
      let elem = $(this).parent().parent()
      rank.moveFighterDown(elem);
    });
    $(".remove").off('click').on("click", function (e) {
      e.preventDefault();
      let elem = $(this).closest("li");
      rank.removeFighterFromRanking(elem)
    });
    $(".add-fighter").off('click').on("click", function (e) {
      e.preventDefault();
      let elem = $(this).closest("li");
      rank.addFighterToRanking(elem);
    });

    $("#search").submit(function (e) {
      e.preventDefault();
    });

    $("#search-fighters--input").off('keyup').on('keyup', function () {
      if (rank.weightClass === "mp4p" || rank.weightClass === "wp4p") {
        let searchText = this.value.toUpperCase();
        let found = [];
        let $list = $("#fighter-select-list")
        let hasFilter = searchText.length > 0;
        $list.toggleClass("filtered", hasFilter);

        if (searchText.length == 0) {
          $list.find("li").each(function (i, el) {
            if ($(el).data("fighter-trending") == false) {
              $(el).remove();
            }
          })
        } else {
          $list.find("li").each(function (i, el) {
            if ($(el).hasClass("found")) {
              $(el).remove();
            }
          })
        }
        if (searchText.length > 0) {
          rank.fighters.forEach((el) => {
            if (el.rank == null) {
              let name = el.first_name + " " + el.last_name
              if (name.toUpperCase().includes(searchText)) {
                found.push(el)
              }
            }
          })
          var BreakException = {};
          found.forEach((fighter) => {
            //makesure fighter isn't already in another list.
            let found = false;
            try {
              $("#ranking-list li").each(function () {
                let id = $(this).data("fighter-id");
                if (fighter.id === id) {
                  found = true;
                  throw BreakException
                }
              });
              $("#temp-list li").each(function () {
                let id = $(this).data("fighter-id");
                if (fighter.id === id) {
                  found = true;
                  throw BreakException
                }
              });
            } catch (e) {
              if (e !== BreakException) throw e;
            }
            if (!found) {
              console.log(fighter);
              $list.append(rank.generateFighter(fighter));
              $(".add-fighter").off('click').on("click", function (e) {
                e.preventDefault();
                let elem = $(this).closest("li");
                rank.addFighterToRanking(elem);
              });

            }
          });
          rank.initButtons();
        }

      } else {


        if ($("#sort-button--trending").hasClass("sort-button--active")) {
          rank.showAllFighters();
        }
        let searchText = this.value;
        let searchTerms = searchText.replace(/ /g, "|");
        let searchRegex = new RegExp(searchTerms, "i");

        let hasFilter = searchText.length > 0;

        let $list = $("#fighter-select-list")

        $list.toggleClass("filtered", hasFilter);
        // $("#filter-results").toggleClass("filtered", hasFilter);

        $list.find("li").each(function (i, el) {
          let $el = $(el)
          let elText = $el.text();
          let match = searchRegex.test(elText);
          $el.toggleClass("found", match)
        })

      }
    });

    $("#filter-clear").click(function (e) {
      e.preventDefault();
      $("#filter-search").val("").change();
    })

  }

  addFighterToRanking(item) {
    $("#errorMessage").hide();

    let li = $(item).closest("li");
    let listSize = $('#ranking-list li').length
    if (listSize > this.maxFighters) {
      li.remove().appendTo('#temp-list');
    } else {
      li.remove().appendTo('#ranking-list');
    }
    rank.reorderList();
    rank.removeAddFightersFromSelectionList(item, true)
  }

  removeFighterFromRanking(item) {
    let li = $(item).closest("li");
    li.remove().appendTo('#fighter-select-list');
    rank.reorderList();
    rank.removeAddFightersFromSelectionList(item, false)
  }

  moveFighterUp(item) {
    let ul = $(item).closest("ul");
    let currentList = $(ul).attr('id')
    if (currentList === "temp-list" && $(item).is(':first-child')) {
      let lastRank = $('#ranking-list').children('li:last-child');
      lastRank.remove().appendTo('#temp-list');
      item.remove().appendTo('#ranking-list');
      rank.initButtons();
    } else {
      item.prev().before(item);
    }
  }

  moveFighterDown(item) {
    let ul = $(item).closest("ul");
    let currentList = $(ul).attr('id')
    if (currentList === "ranking-list" && $(item).is(':last-child') && $('#temp-list li').length >= 0) {
      let lastTemp = $('#temp-list').children('li:first-child');
      lastTemp.remove().appendTo('#ranking-list');
      item.remove().prependTo('#temp-list');
      rank.initButtons();
    } else {
      item.next().after(item);
    }
  }

  initSorting(e) {
    let count = 0;
    let glob = this;
    $("#ranking-list").sortable({
      items: 'li[id!=interim]',
      change: this.reorderList,
      update: this.reorderList,
    });
    $("#temp-list").sortable({
      connectWith: ".connectedSortable"
    })
    $("#fighter-select-list").sortable({
      handle: '.drag-bumps',
      connectWith: ".connectedSortable",
      receive: function (event, ui) {
        if (++count > 1) {
          $("#ranking-list").sortable("option", {connectWith: ""});
        }
      },
      remove: function (event, ui) {
        if (count > 0 && --count <= 1)
          $("#fighter-select-list").sortable("option", {connectWith: ".connectedSortable"});
      }
    }).disableSelection();
  };


  showTrendingFighters() {
    $("#sort-button--trending").addClass("sort-button--active")
    $("#sort-button--all").removeClass("sort-button--active")
    let count = 0;
    $("#fighter-select-list li").each(function (index) {
      let trending = $(this).data("fighter-trending");
      if (!trending) {
        $(this).hide();
      } else {
        count++;
        $(this).show();
      }
    });
    if (count == 0) {
      this.showAllFighters();
    }
  }

  showAllFighters() {
    $("#sort-button--trending").removeClass("sort-button--active")
    $("#sort-button--all").addClass("sort-button--active")

    $("#fighter-select-list li").each(function (index) {
      $(this).show();

    });
  }

  reorderList() {
    $("#errorMessage").hide();

    let listSize = $('#ranking-list li').length
    let tempListSize = $('#temp-list li').length
    if (listSize > rank.maxFighters) {
      $('#ranking-list > li').slice(rank.maxFighters, listSize).appendTo('#temp-list');
    }
    if (listSize < rank.maxFighters && tempListSize > 0) {
      let extraSpace = rank.maxFighters - listSize
      $('#temp-list > li').slice(0, extraSpace).appendTo('#ranking-list');
    }
    rank.initButtons();
  }

  getRankedFighters() {
    let silhouette = "/fighter_images/silhouette.png";

    let ranking = 1;
    if (this.champion != null) {
      ranking = 1;
      let imgsrc = "/fighter_images/" + this.champion.id + ".png";
      if (this.champion.img.includes("silhouette")) {
        imgsrc = silhouette;
      }
      rank.rankedFighters = [{
        first_name: this.champion.first_name,
        last_name: this.champion.last_name,
        img: imgsrc,
        rank: this.image_text.champion
      }];
    } else {
      rank.rankedFighters = []
    }
    rank.hasChampion = this.champion != null

    $("#ranking-list li").each(function () {
      let first_name = $(this).data("fighter-first-name");
      let last_name = $(this).data("fighter-last-name");
      let id = $(this).data("fighter-id").toString().trim();
      let image = "/fighter_images/" + id + ".png";
      if ($(this).data("fighter-img").includes("silhouette")) {
        image = silhouette;
      }
      let rankText = ""
      //interim
      if ($(this).data("fighter-rank") == 0) {
        ranking = 2;
        rankText = "Interim Champion";
      } else {
        rankText = ranking.toString()
        ranking++;
      }
      rank.rankedFighters.push({
        first_name: first_name,
        last_name: last_name,
        img: image,
        rank: rankText
      })
    });
  }

  renderSharing() {
    this.getRankedFighters();
    $.ajax({
      type: 'GET',
      url: '/fan_rankings/share/',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      },
      success: function (data, textStatus, jqXHR) {
        rank.step2 = $("#rankings-content--step-2").html(); //backup
        $("#rankings-content--step-2").html(data)
        $(".page-footer").css("z-index", -1)
        rank.initSharing();
      },
      error: function (jqXHR, textStatus, errorThrown) {
      }
    });
  }

  goBackToRank() {
    $("#rankings-content--step-2").html(this.step2)
    this.init();
  }

  generateFighter(fighter) {
    let name = fighter.first_name + " " + fighter.last_name
    let li = "<li class=\"found\" data-fighter-id=\"" + fighter.id + "\" data-fighter-first-name=\"" + fighter.first_name + "\" data-fighter-last-name=\"" + fighter.last_name + "\" data-fighter-img=\"" + fighter.img + "\" data-fighter-trending=\"" + fighter.trending + "\">\n" +
      "        <div class=\"fighters-list--row fighters-list--row--fighter\">\n" +
      "          <p>" + name + "</p>\n" +
      "          <img src=\"" + fighter.img + "\" alt=\" " + name + "\" width=\"75\" height=\"auto\"/>\n" +
      "          <button class=\"add-fighter\" type=\"button\">\n" +
      "            <span class=\"visually-hidden\">Add to rankings</span>\n" +
      "            <span class=\"svg-holder\" aria-hidden=\"true\">\n" +
      "                                <span>\n" +
      "                                    <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 14 14\" focusable=\"false\">\n" +
      "                                        <use xlink:href=\"#icon--plus\"/>\n" +
      "                                    </svg>\n" +
      "                                </span>\n" +
      "                            </span>\n" +
      "          </button>\n" +
      "          <button class=\"move-up\" type=\"button\">\n" +
      "            <span class=\"visually-hidden\">Move fighter up</span>\n" +
      "            <span class=\"svg-holder\" aria-hidden=\"true\">\n" +
      "                                        <span>\n" +
      "                                            <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"14\" viewBox=\"0 0 14 14\" focusable=\"false\">\n" +
      "                                                <use xlink:href=\"#icon--up\"/>\n" +
      "                                            </svg>\n" +
      "                                        </span>\n" +
      "                                    </span>\n" +
      "          </button>\n" +
      "          <button class=\"move-down\" type=\"button\">\n" +
      "            <span class=\"visually-hidden\">Move fighter down</span>\n" +
      "            <span class=\"svg-holder\" aria-hidden=\"true\">\n" +
      "                                        <span>\n" +
      "                                            <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"14\" viewBox=\"0 0 14 14\" focusable=\"false\">\n" +
      "                                                <use xlink:href=\"#icon--down\"/>\n" +
      "                                            </svg>\n" +
      "                                        </span>\n" +
      "                                    </span>\n" +
      "          </button>\n" +
      "          <button class=\"remove\" type=\"button\">\n" +
      "            <span class=\"visually-hidden\">Remove fighter</span>\n" +
      "            <span class=\"svg-holder\" aria-hidden=\"true\">\n" +
      "                                        <span>\n" +
      "                                            <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"14\" viewBox=\"0 0 14 14\" focusable=\"false\">\n" +
      "                                                <use xlink:href=\"#icon--x\"/>\n" +
      "                                            </svg>\n" +
      "                                        </span>\n" +
      "                                    </span>\n" +
      "          </button>\n" +
      "          <div class=\"drag-bumps\">\n" +
      "                            <span class=\"svg-holder\">\n" +
      "                                <span>\n" +
      "                                    <svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 8 15\" focusable=\"false\">\n" +
      "                                        <use xlink:href=\"#icon--drag-bumps\"/>\n" +
      "                                    </svg>\n" +
      "                                </span>\n" +
      "                            </span>\n" +
      "          </div>\n" +
      "        </div>\n" +
      "\n" +
      "      </li>"

    return li;
  }

//share logic

  initSharing() {

    const mq = `(max-width: 768px)`;
    // $(".page-footer").css( "zIndex", -1 )


    this.menuMain = document.querySelector('#menu-main');

    // listen for future changes in breakpoint

    // check breakpoint initially
    if (window.matchMedia(mq).matches) {
      this.siteMode = 'mobile';
      this.initMobileShare();
    } else {
      this.siteMode = 'desktop';
      this.initDesktopShare();
    }
    $("#aspect-ratio--group").on('change', function() {
      rank.updatePreview();
    })


    // setup mobile navigation


    // get container that rankings image sits in
    this.rankingsImageContainer = document.querySelector('#image-preview-container');

    // set up canvas
    let type = rank.getType()

    this.initShareButton();
    $("#button-back-to-rank").off('click').on("click", function (e) {
      e.preventDefault();
      rank.goBackToRank();
    });
    $("#update-preview").off('click').on("click", function (e) {
      rank.updatePreview();
    })

    this.initCanvas(this.rankedFighters, '9x16', type);


    // set up share/download button


  }

  initMobileShare() {
    // check if we're moving from desktop, if so undo desktop-specific stuff
    if (this.siteMode == 'desktop') {
    }

    // add hidden class to main navigation
    this.menuMain.classList.add('hidden');
    $('.mobileCTA').css('display', 'flex');
    $('#button-step-3--share').hide();


    //set site mode variable
    this.siteMode = 'mobile';
  }

  initDesktopShare() {
    // check if we're moving from mobile, if so then undo mobile-specific things
    if (this.siteMode == 'mobile') {
      // remove hidden class from main navigation
      this.menuMain.classList.remove('hidden');
    }

    //set site mode variable
    this.siteMode = 'desktop';
  }

  updatePreview() {
    let ratio = "1x1"
    if ($('#aspect-ratio--16x9').is(':checked')) {
      ratio = "16x9"
    } else if ($('#aspect-ratio--9x16').is(':checked')) {
      ratio = "9x16"
    }
    $('#image-preview-container').empty();

    rank.initCanvas(this.rankedFighters, ratio, rank.getType());

  }

  initCanvas(rankingsData, layout, type) {
    let bgImage;

    // check which image layout we're creating
    switch (layout) {
      case '16x9':
        if (type == 'noChamp') {
          bgImage = this.canvasImg16noChamp
        } else if (type == '2Champ') {
          bgImage = this.canvasImg162Champ
        } else {
          bgImage = this.canvasImg16;
        }
        break;
      case '1x1':
        if (type == 'noChamp') {
          bgImage = this.canvasImg1noChamp
        } else if (type == '2Champ') {
          bgImage = this.canvasImg12Champ
        } else {
          bgImage = this.canvasImg1
        }
        break;

      case '9x16':
        if (type == 'noChamp') {
          bgImage = this.canvasImg9noChamp
        } else if (type == '2Champ') {
          bgImage = this.canvasImg92Champ
        } else {
          bgImage = this.canvasImg9
        }
        break;
    }

    // get urls of images to preload from data
    this.rankingsImageURLs = new Array();
    this.rankingsImageURLs.push(bgImage);
    // bg image
    let max = 6;
    if (!rank.hasChampion) {
      max = 5;
    }
    for (let i = 0; i < max; i++) {
      this.rankingsImageURLs.push(rankingsData[i].img); // top-5 photos
    }

    this.rankingsImageList = new Array();
    this.rankingsImageCount = 0;

    let silhouette = "/fighter_images/silhouette.png";


    // loop through and preload images before attempting to draw them to canvas
    this.rankingsImageURLs.forEach((url) => {
        const image = new Image();
        image.src = url;
        $(image).bind('error', function () {
          image.src = silhouette
        });
        image.onload = () => {
          this.rankingsImageCount++;
          if (this.rankingsImageCount === this.rankingsImageURLs.length) {
            if (layout == '16x9') {
              switch (type) {
                case 'interim':
                  rank.drawCanvas16x9(rankingsData, "interim");
                  break;
                case 'noChamp':
                  rank.drawCanvas16x9NoChamp(rankingsData);
                  break;
                case '2Champ':
                  rank.drawCanvas16x9(rankingsData, "2Champ");
                  break;
                default:
                  rank.drawCanvas16x9(rankingsData);
              }
            } else if (layout == '1x1') {
              switch (type) {
                case 'interim':
                  rank.drawCanvas1x1(rankingsData);
                  break;
                case 'noChamp':
                  rank.drawCanvas1x1NoChamp(rankingsData);
                  break;
                case 'twoChamp':
                  rank.drawCanvas1x1(rankingsData, "2Champ");
                  break;
                default:
                  rank.drawCanvas1x1(rankingsData);
              }
            } else if (layout == '9x16') {
              switch (type) {
                case 'interim':
                  rank.drawCanvas9x16(rankingsData);
                  break;
                case 'noChamp':
                  rank.drawCanvas9x16NoChamp(rankingsData);
                  break;
                case 'twoChamp':
                  rank.drawCanvas9x16(rankingsData, "2Champ");
                  break;
                default:
                  rank.drawCanvas9x16(rankingsData);
              }
            }
          }
        }
        this.rankingsImageList.push(image);

      }
    )
    ;

  }

  drawCanvas16x9(rankingsData, type) {
    // vars
    let s = window.devicePixelRatio;
    let w = 1024;
    let h = 576;
    let headerH = 120;
    let champW = 300;
    let champH = 456;
    let createText = this.image_text.create + " ";
    let urlText = 'UFC.com/Fan-Rankings';
    let d = new Date();
    let generatedDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    let fontCond = 'Barlow Condensed';
    let font = 'Barlow';
    let colorGrey60 = '#ABADB1';

    // name of image to be generated from canvas
    this.rankingsFileName = `${rank.weightClassText} rankings.png`;

    // set up canvas
    this.rankingsCanvas = document.createElement('canvas');
    let ctx = this.rankingsCanvas.getContext('2d');
    this.rankingsCanvas.id = 'rankings-canvas';
    this.rankingsCanvas.width = Math.floor(w * s);
    this.rankingsCanvas.height = Math.floor(h * s);
    this.rankingsCanvas.style.width = w + "px";
    this.rankingsCanvas.style.height = h + "px";
    ctx.scale(s, s);

    // draw bg image
    ctx.globalAlpha = 1;
    ctx.drawImage(this.rankingsImageList[0], 0, 0, w, h);

    // draw champ photo
    let aspectRatio = this.getAspectRatio(this.rankingsImageList[1]);
    let imageW = champW - 24;
    let imageH = imageW * aspectRatio;
    ctx.drawImage(this.rankingsImageList[1], 12, h - imageH, imageW, imageH);

    // draw #1-5 photos
    for (let i = 2; i < this.rankingsImageList.length; i++) {
      aspectRatio = this.getAspectRatio(this.rankingsImageList[i]);
      imageW = 145 - 16;
      imageH = imageW * aspectRatio;
      ctx.drawImage(this.rankingsImageList[i], (champW + 8) + (144 * (i - 2)), headerH + (champH / 2) - imageH - 1, imageW, imageH);
    }

    // headline
    ctx.textBaseline = "alphabetic";
    ctx.globalAlpha = 1;
    ctx.fillStyle = '#FFF';
    ctx.font = `normal 800 48px ${fontCond}`;
    ctx.fillText(`MY ${rank.weightClassText.toUpperCase()} RANKINGS`, 32, 55);
    // 'create your own' text
    ctx.fillStyle = colorGrey60;
    ctx.font = `normal 600 22px ${fontCond}`;
    ctx.fillText(createText, 32, 97);
    let createM = ctx.measureText(createText);
    ctx.fillStyle = '#FFF';
    ctx.fillText(urlText, 32 + createM.width, 97);
    let urlM = ctx.measureText(urlText);
    ctx.fillRect(32 + createM.width, 94 + urlM.actualBoundingBoxDescent, urlM.width, 2);
    // 'generated on' text
    ctx.fillStyle = colorGrey60;
    ctx.textAlign = "right";
    ctx.font = `normal 600 16px ${font}`;
    ctx.fillText(`${this.image_text.generated} ${generatedDate}`, w - 32, 97);

    let top5YStart = headerH + 16;
    let col1XStart = 322;
    let col1YStart = 360;
    let col2XStart = 684;
    // champion
    if (rankingsData.length > 0) {
      if (type === "noChamp") {
        this.drawNameChamp16x9(ctx, rankingsData[0], champW / 2, top5YStart, 280, rank.white, rank.black);
      } else {
        this.drawNameChamp16x9(ctx, rankingsData[0], champW / 2, top5YStart, 280);
      }

      // #1
    }
    if (rankingsData.length > 1) {
      if (type === "interim") {
        this.drawNameStacked16x9(ctx, rankingsData[1], champW + 72, top5YStart, 140, rank.gold, rank.black);
      } else if (type === "2Champ") {
        this.drawNameStacked16x9(ctx, rankingsData[1], champW + 72, top5YStart, 140, rank.gold, rank.black);
      } else {
        this.drawNameStacked16x9(ctx, rankingsData[1], champW + 72, top5YStart, 140);
      }
    }
    // #2
    if (rankingsData.length > 2)
      this.drawNameStacked16x9(ctx, rankingsData[2], champW + 216, top5YStart, 140);
    // #3
    if (rankingsData.length > 3)
      this.drawNameStacked16x9(ctx, rankingsData[3], champW + 360, top5YStart, 140);
    // #4
    if (rankingsData.length > 4)
      this.drawNameStacked16x9(ctx, rankingsData[4], champW + 504, top5YStart, 140);
    // #5
    if (rankingsData.length > 5)
      this.drawNameStacked16x9(ctx, rankingsData[5], champW + 648, top5YStart, 140);
    // #6
    if (rankingsData.length > 6)
      this.drawNameInline16x9(ctx, rankingsData[6], col1XStart, col1YStart, 300);
    // #7
    if (rankingsData.length > 7)
      this.drawNameInline16x9(ctx, rankingsData[7], col1XStart, col1YStart + 45, 300);
    // #8
    if (rankingsData.length > 8)
      this.drawNameInline16x9(ctx, rankingsData[8], col1XStart, col1YStart + 90, 300);
    // #9
    if (rankingsData.length > 9)
      this.drawNameInline16x9(ctx, rankingsData[9], col1XStart, col1YStart + 135, 300);
    // #10
    if (rankingsData.length > 10)
      this.drawNameInline16x9(ctx, rankingsData[10], col1XStart, col1YStart + 180, 300);
    // #11
    if (rankingsData.length > 11)
      this.drawNameInline16x9(ctx, rankingsData[11], col2XStart, col1YStart, 300);
    // #12
    if (rankingsData.length > 12)
      this.drawNameInline16x9(ctx, rankingsData[12], col2XStart, col1YStart + 45, 300);
    // #13
    if (rankingsData.length > 13)
      this.drawNameInline16x9(ctx, rankingsData[13], col2XStart, col1YStart + 90, 300);
    // #14
    if (rankingsData.length > 14)
      this.drawNameInline16x9(ctx, rankingsData[14], col2XStart, col1YStart + 135, 300);
    // #15
    if (rankingsData.length > 15)
      this.drawNameInline16x9(ctx, rankingsData[15], col2XStart, col1YStart + 180, 300);

    // convert canvas to image and add to page
    this.rankingsImage = new Image();
    this.rankingsImage.src = this.rankingsCanvas.toDataURL();
    this.rankingsImage.setAttribute('alt', 'Your fan rankings');
    this.rankingsImageContainer.appendChild(this.rankingsImage);
  }

  drawCanvas16x9NoChamp(rankingsData) {
    // vars
    let s = window.devicePixelRatio;
    let w = 1024;
    let h = 576;
    let headerH = 120;
    let champW = 300;
    let champH = 456;
    let createText = this.image_text.create + " ";
    let urlText = 'UFC.com/Fan-Rankings';
    let d = new Date();
    let generatedDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    let fontCond = 'Barlow Condensed';
    let font = 'Barlow';
    let colorGrey60 = '#ABADB1';

    // name of image to be generated from canvas
    this.rankingsFileName = `${rank.weightClassText} rankings.png`;

    // set up canvas
    this.rankingsCanvas = document.createElement('canvas');
    let ctx = this.rankingsCanvas.getContext('2d');
    this.rankingsCanvas.id = 'rankings-canvas';
    this.rankingsCanvas.width = Math.floor(w * s);
    this.rankingsCanvas.height = Math.floor(h * s);
    this.rankingsCanvas.style.width = w + "px";
    this.rankingsCanvas.style.height = h + "px";
    ctx.scale(s, s);

    // draw bg image
    ctx.globalAlpha = 1;
    ctx.drawImage(this.rankingsImageList[0], 0, 0, w, h);

    // draw champ photo
    let aspectRatio = this.getAspectRatio(this.rankingsImageList[1]);
    let imageW = champW - 24;
    let imageH = imageW * aspectRatio;
    ctx.drawImage(this.rankingsImageList[1], 12, h - imageH, imageW, imageH);

    // draw #1-5 photos
    for (let i = 2; i < this.rankingsImageList.length; i++) {
      aspectRatio = this.getAspectRatio(this.rankingsImageList[i]);
      imageW = 181 - 52;
      imageH = imageW * aspectRatio;
      ctx.drawImage(this.rankingsImageList[i], champW + (181 * (i - 2)) + 26, headerH + (champH / 2) - imageH - 1, imageW, imageH);
    }

    // headline
    ctx.textBaseline = "alphabetic";
    ctx.globalAlpha = 1;
    ctx.fillStyle = '#FFF';
    ctx.font = `normal 800 48px ${fontCond}`;
    ctx.fillText(`MY ${rank.weightClassText.toUpperCase()} RANKINGS`, 32, 55);
    // 'create your own' text
    ctx.fillStyle = colorGrey60;
    ctx.font = `normal 600 22px ${fontCond}`;
    ctx.fillText(createText, 32, 97);
    let createM = ctx.measureText(createText);
    ctx.fillStyle = '#FFF';
    ctx.fillText(urlText, 32 + createM.width, 97);
    let urlM = ctx.measureText(urlText);
    ctx.fillRect(32 + createM.width, 94 + urlM.actualBoundingBoxDescent, urlM.width, 2);
    // 'generated on' text
    ctx.fillStyle = colorGrey60;
    ctx.textAlign = "right";
    ctx.font = `normal 600 16px ${font}`;
    ctx.fillText(`${this.image_text.generated} ${generatedDate}`, w - 32, 97);

    let top5YStart = headerH + 16;
    let col1XStart = 322;
    let col1YStart = 360;
    let col2XStart = 684;
    // champion
    if (rankingsData.length > 0) {
      this.drawNameChamp16x9(ctx, rankingsData[0], champW / 2, top5YStart, 280, rank.white, rank.black);

    }
    if (rankingsData.length > 1) {
      this.drawNameStacked16x9(ctx, rankingsData[1], champW + 90.5, top5YStart, 181);

    }
    // #2
    if (rankingsData.length > 2)
      this.drawNameStacked16x9(ctx, rankingsData[2], champW + 271.5, top5YStart, 181);
    // #3
    if (rankingsData.length > 3)
      this.drawNameStacked16x9(ctx, rankingsData[3], champW + 452.5, top5YStart, 181);
    // #4
    if (rankingsData.length > 4)
      this.drawNameStacked16x9(ctx, rankingsData[4], champW + 633.5, top5YStart, 181);
    // #6
    if (rankingsData.length > 5)
      this.drawNameInline16x9(ctx, rankingsData[5], col1XStart, col1YStart, 300);
    // #7
    if (rankingsData.length > 6)
      this.drawNameInline16x9(ctx, rankingsData[6], col1XStart, col1YStart + 45, 300);
    // #8
    if (rankingsData.length > 7)
      this.drawNameInline16x9(ctx, rankingsData[7], col1XStart, col1YStart + 90, 300);
    // #9
    if (rankingsData.length > 8)
      this.drawNameInline16x9(ctx, rankingsData[8], col1XStart, col1YStart + 135, 300);
    // #10
    if (rankingsData.length > 9)
      this.drawNameInline16x9(ctx, rankingsData[9], col1XStart, col1YStart + 180, 300);
    // #11
    if (rankingsData.length > 10)
      this.drawNameInline16x9(ctx, rankingsData[10], col2XStart, col1YStart, 300);
    // #12
    if (rankingsData.length > 11)
      this.drawNameInline16x9(ctx, rankingsData[11], col2XStart, col1YStart + 45, 300);
    // #13
    if (rankingsData.length > 12)
      this.drawNameInline16x9(ctx, rankingsData[12], col2XStart, col1YStart + 90, 300);
    // #14
    if (rankingsData.length > 13)
      this.drawNameInline16x9(ctx, rankingsData[13], col2XStart, col1YStart + 135, 300);
    // #15
    if (rankingsData.length > 14)
      this.drawNameInline16x9(ctx, rankingsData[14], col2XStart, col1YStart + 180, 300);

    // convert canvas to image and add to page
    this.rankingsImage = new Image();
    this.rankingsImage.src = this.rankingsCanvas.toDataURL();
    this.rankingsImage.setAttribute('alt', 'Your fan rankings');
    this.rankingsImageContainer.appendChild(this.rankingsImage);
  }

  drawNameChamp9x16(ctx, data, x, y, maxW, rankColor, rankBGColor) {
    let size1 = 48;
    let size2 = 60;
    let font = 'Barlow Condensed';
    let hPadding = 8;
    let vPadding = 4;
    let rankSize = 18;
    if (!rankBGColor) rankBGColor = rank.black;
    if (!rankColor) rankColor = rank.gold;

    // rank bg
    ctx.textAlign = "left";
    ctx.fillStyle = rankBGColor;
    ctx.font = `normal 700 ${rankSize}px ${font}`;
    let rankText = data.rank.toString().toUpperCase();
    let m = ctx.measureText(rankText);
    ctx.fillRect(x, y, m.width + (hPadding) * 2, rankSize + (vPadding) * 2);

    // rank text
    ctx.fillStyle = rankColor;
    ctx.fillText(rankText, x + hPadding, y + 19);

    // name first
    ctx.fillStyle = rank.black;
    ctx.font = `normal 800 ${size1}px ${font}`;
    ctx.fillText(`${data.first_name.toUpperCase()}`, x, y + 82, maxW);

    // name last
    let d = (size1 * .9) + y;
    ctx.font = `normal 800 ${size2}px ${font}`;
    ctx.fillText(`${data.last_name.toUpperCase()}`, x, y + 136);
  }


  drawCanvas9x16(rankingsData, type) {
    // vars
    let s = window.devicePixelRatio;
    let w = 576;
    let h = 1024;
    let headerH = 162;
    let champH = 200;
    let imageXCenter = 455;
    let createText = this.image_text.create + " ";
    let urlText = 'UFC.com/Fan-Rankings';
    let d = new Date();
    let generatedDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    let fontCond = 'Barlow Condensed';
    let font = 'Barlow';
    let colorGrey60 = '#ABADB1';

    // name of image to be generated from canvas
    this.rankingsFileName = `${rank.weightClassText} rankings.png`;

    // set up canvas
    this.rankingsCanvas = document.createElement('canvas');
    let ctx = this.rankingsCanvas.getContext('2d');
    this.rankingsCanvas.id = 'rankings-canvas';
    this.rankingsCanvas.width = Math.floor(w * s);
    this.rankingsCanvas.height = Math.floor(h * s);
    this.rankingsCanvas.style.width = w + "px";
    this.rankingsCanvas.style.height = h + "px";
    ctx.scale(s, s);

    // draw bg image
    ctx.globalAlpha = 1;
    ctx.drawImage(this.rankingsImageList[0], 0, 0, w, h);

    // draw champ photo
    let aspectRatio = this.getAspectRatio(this.rankingsImageList[1]);
    let imageW = 225;
    let imageH = imageW * aspectRatio;
    ctx.drawImage(this.rankingsImageList[1], imageXCenter - (imageW / 2), headerH + champH - imageH, imageW, imageH);

    // draw #1-5 photos
    for (let i = 2; i < 7; i++) {
      aspectRatio = this.getAspectRatio(this.rankingsImageList[i]);
      imageW = 120;
      imageH = imageW * aspectRatio;
      let x = imageXCenter - (imageW / 2);
      let y = headerH + champH - imageH + (84 * (i - 1));
      ctx.drawImage(this.rankingsImageList[i], x, y, imageW, imageH);
    }

    // headline
    ctx.textBaseline = "alphabetic";
    ctx.globalAlpha = 1;
    ctx.fillStyle = '#FFF';
    ctx.font = `normal 800 40px ${fontCond}`;
    ctx.textAlign = "center";
    ctx.fillText(`MY ${rank.weightClassText.toUpperCase()} RANKINGS`, w / 2, 102);
    // 'create your own' text
    ctx.fillStyle = colorGrey60;
    ctx.textAlign = "left";
    ctx.font = `normal 600 18px ${fontCond}`;
    ctx.fillText(createText, 283, 140);
    let createM = ctx.measureText(createText);
    ctx.fillStyle = '#FFF';
    ctx.fillText(urlText, 283 + createM.width, 140);
    let urlM = ctx.measureText(urlText);
    ctx.fillRect(283 + createM.width, 140 + urlM.actualBoundingBoxDescent, urlM.width, 2);
    // 'generated on' text
    ctx.fillStyle = colorGrey60;
    ctx.textAlign = "left";
    ctx.font = `normal 600 14px ${font}`;
    ctx.fillText(`${this.image_text.generated} ${generatedDate}`,  24, 140);


    // champion
    if (rankingsData.length > 0) {
      if (type === "noChamp") {
        this.drawNameChamp9x16(ctx, rankingsData[0], 24, headerH + 24, 400, rank.white, rank.black);
      } else {
        this.drawNameChamp9x16(ctx, rankingsData[0], 24, headerH + 24, 400);
      }

      // #1
    }
    if (rankingsData.length > 1) {
      if (type === "interim") {
        this.drawNameStacked9x16(ctx, rankingsData[1], 24, 374, 400, rank.gold, rank.black);
      } else if (type === "2Champ") {
        this.drawNameStacked9x16(ctx, rankingsData[1], 24, 374, 400, rank.gold, rank.black);
      } else {
        this.drawNameStacked9x16(ctx, rankingsData[1], 24, 374, 400);
      }
    }
    // #2
    if (rankingsData.length > 2)
      this.drawNameStacked9x16(ctx, rankingsData[2], 24, 458, 400);
    if (rankingsData.length > 3)
      this.drawNameStacked9x16(ctx, rankingsData[3], 24, 542, 400);
    // #3
    if (rankingsData.length > 4)
      this.drawNameStacked9x16(ctx, rankingsData[4], 24, 626, 400);
    // #4
    if (rankingsData.length > 5)
      this.drawNameStacked9x16(ctx, rankingsData[5], 24, 710, 400);
    // #5
    if (rankingsData.length > 6)
      this.drawNameInline9x16(ctx, rankingsData[6], 24, 794, 240);
    // #6
    if (rankingsData.length > 7)
      this.drawNameInline9x16(ctx, rankingsData[7], 24, 843, 240);
    // #7
    if (rankingsData.length > 8)
      this.drawNameInline9x16(ctx, rankingsData[8], 24, 892, 240);
    // #8
    if (rankingsData.length > 9)
      this.drawNameInline9x16(ctx, rankingsData[9], 24, 941, 240);
    // #9
    if (rankingsData.length > 10)
      this.drawNameInline9x16(ctx, rankingsData[10], 24, 990, 240);
    // #10
    if (rankingsData.length > 11)
      this.drawNameInline9x16(ctx, rankingsData[11], 302, 794, 240);
    // #11
    if (rankingsData.length > 12)
      this.drawNameInline9x16(ctx, rankingsData[12], 302, 843, 425);
    // #12
    if (rankingsData.length > 13)
      this.drawNameInline9x16(ctx, rankingsData[13], 302, 892, 425);
    // #13
    if (rankingsData.length > 14)
      this.drawNameInline9x16(ctx, rankingsData[14], 302, 941, 425);
    // #14
    if (rankingsData.length > 15)
      this.drawNameInline9x16(ctx, rankingsData[15], 302, 990, 425);

    // convert canvas to image and add to page
    this.rankingsImage = new Image();
    this.rankingsImage.src = this.rankingsCanvas.toDataURL();
    this.rankingsImage.setAttribute('alt', 'Your fan rankings');
    this.rankingsImageContainer.appendChild(this.rankingsImage);
  }


  drawCanvas9x16NoChamp(rankingsData) {
    // vars
    let s = window.devicePixelRatio;
    let w = 576;
    let h = 1024;
    let headerH = 162;
    let champH = 200;
    let imageXCenter = 455;
    let createText = this.image_text.create + " ";
    let urlText = 'UFC.com/Fan-Rankings';
    let d = new Date();
    let generatedDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    let fontCond = 'Barlow Condensed';
    let font = 'Barlow';
    let colorGrey60 = '#ABADB1';

    // name of image to be generated from canvas
    this.rankingsFileName = `${rank.weightClassText} rankings.png`;

    // set up canvas
    this.rankingsCanvas = document.createElement('canvas');
    let ctx = this.rankingsCanvas.getContext('2d');
    this.rankingsCanvas.id = 'rankings-canvas';
    this.rankingsCanvas.width = Math.floor(w * s);
    this.rankingsCanvas.height = Math.floor(h * s);
    this.rankingsCanvas.style.width = w + "px";
    this.rankingsCanvas.style.height = h + "px";
    ctx.scale(s, s);

    // draw bg image
    ctx.globalAlpha = 1;
    ctx.drawImage(this.rankingsImageList[0], 0, 0, w, h);

    // draw champ photo
    let aspectRatio = this.getAspectRatio(this.rankingsImageList[1]);
    let imageW = 225;
    let imageH = imageW * aspectRatio;
    ctx.drawImage(this.rankingsImageList[1], imageXCenter - (imageW / 2), headerH + champH - imageH, imageW, imageH);

    // draw #1-5 photos
    for (let i = 2; i < this.rankingsImageList.length; i++) {
      aspectRatio = this.getAspectRatio(this.rankingsImageList[i]);
      imageW = 150;
      imageH = imageW * aspectRatio;
      let x = imageXCenter - (imageW / 2);
      let y = headerH + champH - imageH + (84 * (i - 1));
      ctx.drawImage(this.rankingsImageList[i], x, y, imageW, imageH);
    }

    // headline
    ctx.textBaseline = "alphabetic";
    ctx.globalAlpha = 1;
    ctx.fillStyle = '#FFF';
    ctx.font = `normal 800 40px ${fontCond}`;
    ctx.textAlign = "center";
    ctx.fillText(`MY ${rank.weightClassText.toUpperCase()} RANKINGS`, w / 2, 102);
    // 'create your own' text
    ctx.fillStyle = colorGrey60;
    ctx.textAlign = "left";
    ctx.font = `normal 600 18px ${fontCond}`;
    ctx.fillText(createText, 283, 140);
    let createM = ctx.measureText(createText);
    ctx.fillStyle = '#FFF';
    ctx.fillText(urlText, 283 + createM.width, 140);
    let urlM = ctx.measureText(urlText);
    ctx.fillRect(283 + createM.width, 140 + urlM.actualBoundingBoxDescent, urlM.width, 2);
    // 'generated on' text
    ctx.fillStyle = colorGrey60;
    ctx.textAlign = "left";
    ctx.font = `normal 600 14px ${font}`;
    ctx.fillText(`${this.image_text.generated} ${generatedDate}`,  24, 140);


    // champion
    if (rankingsData.length > 0) {

      this.drawNameChamp9x16(ctx, rankingsData[0], 24, headerH + 24, 400, rank.white, rank.black);
    }
    if (rankingsData.length > 1) {
      this.drawNameStacked9x16(ctx, rankingsData[1], 24, 374, 400);
    }
    // #2
    if (rankingsData.length > 2)
      this.drawNameStacked9x16(ctx, rankingsData[2], 24, 479, 400);
    if (rankingsData.length > 3)
      this.drawNameStacked9x16(ctx, rankingsData[3], 24, 584, 400);
    // #3
    if (rankingsData.length > 4)
      this.drawNameStacked9x16(ctx, rankingsData[4], 24, 689, 400);
    // #4

    if (rankingsData.length > 5)
      this.drawNameInline9x16(ctx, rankingsData[5], 24, 794, 240);
    // #6
    if (rankingsData.length > 6)
      this.drawNameInline9x16(ctx, rankingsData[7], 24, 843, 240);
    // #7
    if (rankingsData.length > 7)
      this.drawNameInline9x16(ctx, rankingsData[7], 24, 892, 240);
    // #8
    if (rankingsData.length > 8)
      this.drawNameInline9x16(ctx, rankingsData[8], 24, 941, 240);
    // #9
    if (rankingsData.length > 9)
      this.drawNameInline9x16(ctx, rankingsData[9], 24, 990, 240);
    // #10
    if (rankingsData.length > 10)
      this.drawNameInline9x16(ctx, rankingsData[10], 302, 794, 240);
    // #11
    if (rankingsData.length > 11)
      this.drawNameInline9x16(ctx, rankingsData[11], 302, 843, 425);
    // #12
    if (rankingsData.length > 12)
      this.drawNameInline9x16(ctx, rankingsData[12], 302, 892, 425);
    // #13
    if (rankingsData.length > 13)
      this.drawNameInline9x16(ctx, rankingsData[13], 302, 941, 425);
    // #14
    if (rankingsData.length > 14)
      this.drawNameInline9x16(ctx, rankingsData[14], 302, 990, 425);

    // convert canvas to image and add to page
    this.rankingsImage = new Image();
    this.rankingsImage.src = this.rankingsCanvas.toDataURL();
    this.rankingsImage.setAttribute('alt', 'Your fan rankings');
    this.rankingsImageContainer.appendChild(this.rankingsImage);
  }


  drawCanvas1x1(rankingsData, type) {
    // vars
    let s = window.devicePixelRatio;
    let w = 1024;
    let h = 1024;
    let headerH = 120;
    let champW = 1024;
    let champH = 301;
    let createText = 'Create your own at ';
    let urlText = 'UFC.com/Fan-Rankings';
    let d = new Date();
    let generatedDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    let fontCond = 'Barlow Condensed';
    let font = 'Barlow';
    let colorGrey60 = '#ABADB1';

    // name of image to be generated from canvas
    this.rankingsFileName = `${rank.weightClassText} rankings.png`;

    // set up canvas
    this.rankingsCanvas = document.createElement('canvas');
    let ctx = this.rankingsCanvas.getContext('2d');
    this.rankingsCanvas.id = 'rankings-canvas';
    this.rankingsCanvas.width = Math.floor(w * s);
    this.rankingsCanvas.height = Math.floor(h * s);
    this.rankingsCanvas.style.width = w + "px";
    this.rankingsCanvas.style.height = h + "px";
    ctx.scale(s, s);

    // draw bg image
    ctx.globalAlpha = 1;
    ctx.drawImage(this.rankingsImageList[0], 0, 0, w, h);

    // draw champ photo
    let aspectRatio = this.getAspectRatio(this.rankingsImageList[1]);
    let imageW = 370;
    let imageH = imageW * aspectRatio;
    ctx.drawImage(this.rankingsImageList[1], w - 96 - imageW, headerH + champH - imageH, imageW, imageH);

    // draw #1-5 photos
    for (let i = 2; i < this.rankingsImageList.length; i++) {
      aspectRatio = this.getAspectRatio(this.rankingsImageList[i]);
      imageW = 190;
      imageH = imageW * aspectRatio;
      let x = ((w / 5) / 2) + ((w / 5) * (i - 2)) - (imageW / 2);
      ctx.drawImage(this.rankingsImageList[i], x, headerH + (champH * 2) - imageH - 1, imageW, imageH);
    }

    // headline
    ctx.textBaseline = "alphabetic";
    ctx.globalAlpha = 1;
    ctx.fillStyle = '#FFF';
    ctx.font = `normal 800 48px ${fontCond}`;
    ctx.fillText(`MY ${rank.weightClassText.toUpperCase()} RANKINGS`, 32, 55);
    // 'create your own' text
    ctx.fillStyle = colorGrey60;
    ctx.font = `normal 600 22px ${fontCond}`;
    ctx.fillText(createText, 32, 97);
    let createM = ctx.measureText(createText);
    ctx.fillStyle = '#FFF';
    ctx.fillText(urlText, 32 + createM.width, 97);
    let urlM = ctx.measureText(urlText);
    ctx.fillRect(32 + createM.width, 94 + urlM.actualBoundingBoxDescent, urlM.width, 2);
    // 'generated on' text
    ctx.fillStyle = colorGrey60;
    ctx.textAlign = "right";
    ctx.font = `normal 600 16px ${font}`;
    ctx.fillText(`Generated on ${generatedDate}`, w - 32, 97);

    let top5YStart = headerH + 60;
    let col1YStart = 735;
    let col2XStart = 684;

    // champion
    if (rankingsData.length > 0) {
      if (type === "noChamp") {
        rank.drawNameChamp1x1(ctx, rankingsData[0], 96, top5YStart, 575, rank.white, rank.black);
      } else {
        rank.drawNameChamp1x1(ctx, rankingsData[0], 96, top5YStart, 575);
      }
    }
    // #1
    if (rankingsData.length > 1) {
      if (type === "2Champ") {
        rank.drawNameStacked1x1(ctx, rankingsData[1], 102, 444, 196, rank.gold, rank.black);
      } else {
        rank.drawNameStacked1x1(ctx, rankingsData[1], 102, 444, 196);

      }
    }
    // #2
    if (rankingsData.length > 2)
      rank.drawNameStacked1x1(ctx, rankingsData[2], 307, 444, 196);
    // #3
    if (rankingsData.length > 3)
      rank.drawNameStacked1x1(ctx, rankingsData[3], 511, 444, 196);
    // #4
    if (rankingsData.length > 4)
      rank.drawNameStacked1x1(ctx, rankingsData[4], 717, 444, 196);
    // #5
    if (rankingsData.length > 5)
      rank.drawNameStacked1x1(ctx, rankingsData[5], 921, 444, 196);
    // #6
    if (rankingsData.length > 6)
      rank.drawNameInline1x1(ctx, rankingsData[6], 32, col1YStart, 425);
    // #7
    if (rankingsData.length > 7)
      rank.drawNameInline1x1(ctx, rankingsData[7], 32, col1YStart + 61, 425);
    // #8
    if (rankingsData.length > 8)
      rank.drawNameInline1x1(ctx, rankingsData[8], 32, col1YStart + 122, 425);
    // #9
    if (rankingsData.length > 9)
      rank.drawNameInline1x1(ctx, rankingsData[9], 32, col1YStart + 183, 425);
    // #10
    if (rankingsData.length > 10)
      rank.drawNameInline1x1(ctx, rankingsData[10], 32, col1YStart + 244, 425);
    // #11
    if (rankingsData.length > 11)
      rank.drawNameInline1x1(ctx, rankingsData[11], 544, col1YStart, 425);
    // #12
    if (rankingsData.length > 12)
      rank.drawNameInline1x1(ctx, rankingsData[12], 544, col1YStart + 61, 425);
    // #13
    if (rankingsData.length > 13)
      rank.drawNameInline1x1(ctx, rankingsData[13], 544, col1YStart + 122, 425);
    // #14
    if (rankingsData.length > 14)
      rank.drawNameInline1x1(ctx, rankingsData[14], 544, col1YStart + 183, 425);
    // #15
    if (rankingsData.length > 15)
      rank.drawNameInline1x1(ctx, rankingsData[15], 544, col1YStart + 244, 425);

    // convert canvas to image and add to page
    this.rankingsImage = new Image();
    this.rankingsImage.src = this.rankingsCanvas.toDataURL();
    this.rankingsImage.setAttribute('alt', 'Your fan rankings');
    this.rankingsImageContainer.appendChild(this.rankingsImage);
  }

  drawCanvas1x1NoChamp(rankingsData) {
    // vars
    let s = window.devicePixelRatio;
    let w = 1024;
    let h = 1024;
    let headerH = 120;
    let champW = 1024;
    let champH = 301;
    let createText = 'Create your own at ';
    let urlText = 'UFC.com/Fan-Rankings';
    let d = new Date();
    let generatedDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
    let fontCond = 'Barlow Condensed';
    let font = 'Barlow';
    let colorGrey60 = '#ABADB1';

    // name of image to be generated from canvas
    this.rankingsFileName = `${rank.weightClassText} rankings.png`;

    // set up canvas
    this.rankingsCanvas = document.createElement('canvas');
    let ctx = this.rankingsCanvas.getContext('2d');
    this.rankingsCanvas.id = 'rankings-canvas';
    this.rankingsCanvas.width = Math.floor(w * s);
    this.rankingsCanvas.height = Math.floor(h * s);
    this.rankingsCanvas.style.width = w + "px";
    this.rankingsCanvas.style.height = h + "px";
    ctx.scale(s, s);

    // draw bg image
    ctx.globalAlpha = 1;
    ctx.drawImage(this.rankingsImageList[0], 0, 0, w, h);

    // draw champ photo
    let aspectRatio = this.getAspectRatio(this.rankingsImageList[1]);
    let imageW = 370;
    let imageH = imageW * aspectRatio;
    ctx.drawImage(this.rankingsImageList[1], w - 96 - imageW, headerH + champH - imageH, imageW, imageH);

    // draw #1-5 photos
    for (let i = 2; i < this.rankingsImageList.length; i++) {
      aspectRatio = rank.getAspectRatio(this.rankingsImageList[i]);
      imageW = 200;
      imageH = imageW * aspectRatio;
      let x = ((w / 4) / 2) + ((w / 4) * (i - 2)) - (imageW / 2);
      ctx.drawImage(this.rankingsImageList[i], x, headerH + (champH * 2) - imageH - 1, imageW, imageH);
    }

    // headline
    ctx.textBaseline = "alphabetic";
    ctx.globalAlpha = 1;
    ctx.fillStyle = '#FFF';
    ctx.font = `normal 800 48px ${fontCond}`;
    ctx.fillText(`MY ${rank.weightClassText.toUpperCase()} RANKINGS`, 32, 55);
    // 'create your own' text
    ctx.fillStyle = colorGrey60;
    ctx.font = `normal 600 22px ${fontCond}`;
    ctx.fillText(createText, 32, 97);
    let createM = ctx.measureText(createText);
    ctx.fillStyle = '#FFF';
    ctx.fillText(urlText, 32 + createM.width, 97);
    let urlM = ctx.measureText(urlText);
    ctx.fillRect(32 + createM.width, 94 + urlM.actualBoundingBoxDescent, urlM.width, 2);
    // 'generated on' text
    ctx.fillStyle = colorGrey60;
    ctx.textAlign = "right";
    ctx.font = `normal 600 16px ${font}`;
    ctx.fillText(`Generated on ${generatedDate}`, w - 32, 97);

    let top5YStart = headerH + 60;
    let col1YStart = 735;
    let col2XStart = 684;

    // champion
    if (rankingsData.length > 0) {

      rank.drawNameChamp1x1(ctx, rankingsData[0], 96, top5YStart, 575, rank.white, rank.black);
    }
    // #1

    if (rankingsData.length > 1) {

      rank.drawNameStacked1x1(ctx, rankingsData[1], 128, 444, 240);

    }

    // #2
    if (rankingsData.length > 2)
      rank.drawNameStacked1x1(ctx, rankingsData[2], 384, 444, 240);
    // #3
    if (rankingsData.length > 3)
      rank.drawNameStacked1x1(ctx, rankingsData[3], 640, 444, 240);
    // #4
    if (rankingsData.length > 4)
      rank.drawNameStacked1x1(ctx, rankingsData[4], 896, 444, 240);
    // #5
    if (rankingsData.length > 5)
      rank.drawNameInline1x1(ctx, rankingsData[5], 32, col1YStart, 425);
    // #6
    if (rankingsData.length > 6)
      rank.drawNameInline1x1(ctx, rankingsData[6], 32, col1YStart + 61, 425);
    // #7
    if (rankingsData.length > 7)
      rank.drawNameInline1x1(ctx, rankingsData[7], 32, col1YStart + 122, 425);
    // #8
    if (rankingsData.length > 8)
      rank.drawNameInline1x1(ctx, rankingsData[8], 32, col1YStart + 183, 425);
    // #9
    if (rankingsData.length > 9)
      rank.drawNameInline1x1(ctx, rankingsData[9], 32, col1YStart + 244, 425);
    // #10
    if (rankingsData.length > 10)
      rank.drawNameInline1x1(ctx, rankingsData[10], 544, col1YStart, 425);
    // #11
    if (rankingsData.length > 11)
      rank.drawNameInline1x1(ctx, rankingsData[11], 544, col1YStart + 61, 425);
    // #12
    if (rankingsData.length > 12)
      rank.drawNameInline1x1(ctx, rankingsData[12], 544, col1YStart + 122, 425);
    // #13
    if (rankingsData.length > 13)
      rank.drawNameInline1x1(ctx, rankingsData[13], 544, col1YStart + 183, 425);
    // #14
    if (rankingsData.length > 14)
      rank.drawNameInline1x1(ctx, rankingsData[14], 544, col1YStart + 244, 425);
    // #15

    // convert canvas to image and add to page
    this.rankingsImage = new Image();
    this.rankingsImage.src = this.rankingsCanvas.toDataURL();
    this.rankingsImage.setAttribute('alt', 'Your fan rankings');
    this.rankingsImageContainer.appendChild(this.rankingsImage);
  }


  getAspectRatio(img) {
    return img.height / img.width;
  }

  drawNameInline16x9(ctx, data, x, y, maxW) {
    let rankSize = 16;
    let font = 'Barlow Condensed';
    let size = 18;
    let vPadding = 6;
    let textY = y + (vPadding / 2);

    // rank bg
    ctx.textAlign = "center";
    ctx.fillStyle = rank.grey;
    ctx.font = `normal 700 ${rankSize}px ${font}`;
    let rankText = data.rank.toString().toUpperCase();
    let m = ctx.measureText(rankText);
    ctx.fillRect(x, y, 24, 25);

    // rank text
    ctx.fillStyle = rank.white;
    ctx.fillText(rankText, x + 12, y + 18.5);

    // name first
    ctx.textAlign = "left";
    ctx.fillStyle = rank.black;
    ctx.font = `normal 800 ${size}px ${font}`;
    ctx.fillText(`${data.first_name.toUpperCase()} ${data.last_name.toUpperCase()}`, x + 36, y + 18.5, maxW);
  }

  drawNameInline1x1(ctx, data, x, y, maxW) {
    let rankSize = 24;
    let font = 'Barlow Condensed';
    let size = 26;

    // rank bg
    ctx.textAlign = "center";
    ctx.fillStyle = rank.grey;
    ctx.font = `normal 700 ${rankSize}px ${font}`;
    let rankText = data.rank.toString().toUpperCase();
    let m = ctx.measureText(rankText);
    ctx.fillRect(x, y, 32, 32);

    // rank text
    ctx.fillStyle = rank.white;
    ctx.fillText(rankText, x + 16, y + 24);

    // name first
    ctx.textAlign = "left";
    ctx.fillStyle = rank.black;
    ctx.font = `normal 800 ${size}px ${font}`;
    ctx.fillText(`${data.first_name.toUpperCase()} ${data.last_name.toUpperCase()}`, x + 64, y + 24, maxW);
  }

  drawNameStacked9x16(ctx, data, x, y, maxW, rankColor, rankBGColor) {
    let rankSize = 16;
    let font = 'Barlow Condensed';
    let size1 = 28;
    if (!rankBGColor) rankBGColor = '#888891';
    if (!rankColor) rankColor = '#FFFFFF';

    // rank bg
    ctx.textAlign = "center";
    ctx.fillStyle = rankBGColor;
    ctx.font = `normal 700 ${rankSize}px ${font}`;

    let rankText = data.rank.toString().toUpperCase();
    let m = ctx.measureText(rankText);
    ctx.fillRect(x, y, m.width + 16, 22);

    // rank text
    ctx.fillStyle = rankColor;
    ctx.fillText(rankText, x + ((m.width + 16) / 2), y + 17);

    // name first and last
    ctx.textAlign = "left";
    ctx.fillStyle = rank.black;
    ctx.font = `normal 800 ${size1}px ${font}`;
    ctx.fillText(`${data.first_name.toUpperCase()} ${data.last_name.toUpperCase()}`, x, y + 56, maxW);
  }

  drawNameStacked16x9(ctx, data, x, y, maxW, rankColor, rankBGColor) {
    let rankSize = 16;
    let font = 'Barlow Condensed';
    let size1 = 22;
    let size2 = 30;
    let hPadding = 16;
    if (!rankBGColor) rankBGColor = '#888891';
    if (!rankColor) rankColor = '#FFFFFF';

    // rank bg
    ctx.textAlign = "center";
    ctx.fillStyle = rankBGColor;
    ctx.font = `normal 700 ${rankSize}px ${font}`;
    let rankText = data.rank.toString().toUpperCase();
    let m = ctx.measureText(rankText);
    ctx.fillRect(x - ((m.width + hPadding) / 2), y, m.width + hPadding, 25);

    // rank text
    ctx.fillStyle = rankColor;
    ctx.fillText(rankText, x, y + 19);

    // name first
    ctx.fillStyle = rank.black;
    ctx.font = `normal 800 ${size1}px ${font}`;
    ctx.fillText(`${data.first_name.toUpperCase()}`, x, y + 56);

    // name last
    ctx.font = `normal 800 ${size2}px ${font}`;
    ctx.fillText(`${data.last_name.toUpperCase()}`, x, y + 83, maxW);
  }

  drawNameInline9x16(ctx, data, x, y, maxW) {
    let rankSize = 16;
    let font = 'Barlow Condensed';
    let size = 18;

    // rank bg
    ctx.textAlign = "center";
    ctx.fillStyle = rank.grey;
    ctx.font = `normal 700 ${rankSize}px ${font}`;
    let rankText = data.rank.toString().toUpperCase();
    let m = ctx.measureText(rankText);
    ctx.fillRect(x, y, 24, 22);

    // rank text
    ctx.fillStyle = rank.white;
    ctx.fillText(rankText, x + 12, y + 17);

    // name first
    ctx.textAlign = "left";
    ctx.fillStyle = rank.black;
    ctx.font = `normal 800 ${size}px ${font}`;
    ctx.fillText(`${data.first_name.toUpperCase()} ${data.last_name.toUpperCase()}`, x + 36, y + 18, maxW);
  }

  drawNameStacked1x1(ctx, data, x, y, maxW, rankColor, rankBGColor) {
    let rankSize = 24;
    let font = 'Barlow Condensed';
    let size1 = 30;
    let size2 = 36;
    if (!rankBGColor) rankBGColor = '#888891';
    if (!rankColor) rankColor = '#FFFFFF';

    // rank bg
    ctx.textAlign = "center";
    ctx.fillStyle = rankBGColor;
    ctx.font = `normal 700 ${rankSize}px ${font}`;
    let rankText = data.rank.toString().toUpperCase();
    let m = ctx.measureText(rankText);
    ctx.fillRect(x - ((m.width + 16) / 2), y, m.width + 16, 32);

    // rank text
    ctx.fillStyle = rankColor;
    ctx.fillText(rankText, x, y + 24);

    // name first
    ctx.fillStyle = rank.black;
    ctx.font = `normal 800 ${size1}px ${font}`;
    ctx.fillText(`${data.first_name.toUpperCase()}`, x, y + 70, maxW);

    // name last
    let d = (size1 * .9) + y;
    ctx.font = `normal 800 ${size2}px ${font}`;
    ctx.fillText(`${data.last_name.toUpperCase()}`, x, y + 105, maxW);
  }

  drawNameChamp1x1(ctx, data, x, y, maxW, rankColor, rankBGColor) {
    let size1 = 60;
    let size2 = 80;
    let font = 'Barlow Condensed';
    let hPadding = 24;
    let vPadding = 6;
    let rankSize = 24;
    let rankSpace = 24;
    if (!rankBGColor) rankBGColor = rank.black;
    if (!rankColor) rankColor = rank.gold;

    // rank bg
    ctx.textAlign = "left";
    ctx.fillStyle = rankBGColor;
    ctx.font = `normal 700 ${rankSize}px ${font}`;
    let rankText = data.rank.toString().toUpperCase();
    let m = ctx.measureText(rankText);
    ctx.fillRect(x, y, m.width + hPadding, rankSize + vPadding);

    // rank text
    ctx.fillStyle = rankColor;
    ctx.fillText(rankText, x + (hPadding / 2), y + (vPadding / 2) + 20);

    // name first
    ctx.fillStyle = rank.black;
    ctx.font = `normal 800 ${size1}px ${font}`;
    ctx.fillText(`${data.first_name.toUpperCase()}`, x, y + 98, maxW);

    // name last
    let d = (size1 * .9) + y;
    ctx.font = `normal 800 ${size2}px ${font}`;
    ctx.fillText(`${data.last_name.toUpperCase()}`, x, y + 173, maxW);
  }

  drawNameChamp16x9(ctx, data, x, y, maxW, rankColor, rankBGColor) {
    let size1 = 48;
    let size2 = 60;
    let font = 'Barlow Condensed';
    let hPadding = 16;
    let rankSize = 18;
    if (!rankBGColor) rankBGColor = rank.black;
    if (!rankColor) rankColor = rank.gold;

    // rank bg
    ctx.textAlign = "center";
    ctx.fillStyle = rank.black;
    ctx.font = `normal 700 ${rankSize}px ${font}`;
    let rankText = data.rank.toString().toUpperCase();
    let m = ctx.measureText(rankText);
    ctx.fillRect(x - (m.width / 2) - (hPadding / 2), y, m.width + hPadding, 25);

    // rank text
    ctx.fillStyle = rankColor;
    ctx.fillText(rankText, x, y + 19);

    // name first
    ctx.fillStyle = rankBGColor;
    ctx.font = `normal 800 ${size1}px ${font}`;
    ctx.fillText(`${data.first_name.toUpperCase()}`, x, y + 83);

    // name last
    ctx.font = `normal 800 ${size2}px ${font}`;
    ctx.fillText(`${data.last_name.toUpperCase()}`, x, y + 137, maxW);
  }

  initShareButton() {
    this.shareButton = document.querySelector('#button-step-3--share');
    this.shareButton.addEventListener('click', (e) => {
      // share data
      let file = new File([this.rankingsImage], `${this.rankingsFileName}.png`, {type: 'image/png'});
      let filesArray = [file];
      let shareData = {
        files: filesArray,
        title: 'UFC Fan Rankings',
        text: `Your rankings`
      };

      // check if user's device/browser can share files (Web Share API Level 2)
      if (navigator.canShare && navigator.canShare({files: filesArray})) {
        console.log('Sharing files supported...');

        navigator
          // trigger the share
          .share(shareData)
          // if successful
          .then(() => {
            console.log('...and share completed.');
          })
          // if not successful
          .catch((error) => {
            // false positive
            // this is probably because the user's browser supports sharing files, but the device does not (ex: Chrome on deskop)
            console.log('...but can not happen: ', error);
            // trigger manual download of image
            this.downloadRankingsImage();
          })
        ;
      } else {
        console.log('Sharing files NOT supported');
        // trigger manual download of image
        this.downloadRankingsImage();
      }
    });
  }

  downloadRankingsImage() {
    let a = document.createElement('a');
    a.download = this.rankingsFileName;
    a.href = this.rankingsImage.src;
    a.click();
  }

  getType() {
    let type = null;
    rank.hasChampion = rank.champion != null
    if (rank.hasInterimChamp && rank.hasChampion) {
      type = "2Champ";
    } else if (rank.hasInterimChamp && !rank.hasChampion) {
      type = "interim";
    } else if (!rank.hasChampion && !rank.hasInterimChamp) {
      type = "noChamp";
    } else {
      type = null;
    }

    return type
  }


}

export let rank = new Rank();
