export class WeightClassSelect {

  static checkBreakpoint(e, weight_class_select_object) {
      // switch to mobile functionality
      if(e.matches) {
          weight_class_select_object.initMobile();
      // switch to non-mobile functionality
      } else {
          weight_class_select_object.initDesktop();
      }
  };

  initMobile() {
      // check if we're moving from desktop, if so undo desktop-specific stuff
      if(this.siteMode == 'desktop') {}

      // add hidden class to main navigation
      this.menuMain.classList.add('hidden');
      
      //set site mode variable
      this.siteMode = 'mobile';
  };

  initDesktop() {
      // check if we're moving from mobile, if so then undo mobile-specific things
      if(this.siteMode == 'mobile') {
          // remove hidden class from main navigation
          this.menuMain.classList.remove('hidden');
      }

      //set site mode variable
      this.siteMode = 'desktop';
  };

  init() {
      this.mq = `(max-width: 768px)`;

      // get elements
      this.mobileNavToggle = document.querySelector('#menu-main--mobile-menu-toggle');
      this.menuMain = document.querySelector('#menu-main');
      
      // check breakpoint initially
      if(window.matchMedia(this.mq).matches) {
          this.siteMode = 'mobile';
          this.initMobile();
      } else {
          this.siteMode = 'desktop';
          this.initDesktop();
      }

      // setup mobile navigation
      this.mobileNavToggle.addEventListener('click',(e)=>{
          if(e.currentTarget.getAttribute('aria-expanded')=='true') {
              e.currentTarget.setAttribute('aria-expanded','false');
              this.menuMain.classList.add('hidden');
          } else {
              e.currentTarget.setAttribute('aria-expanded','true');
              this.menuMain.classList.remove('hidden');
          }
      });
  };
}

export let weight_class_select = new WeightClassSelect();