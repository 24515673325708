class Sidebar {

  attachHandlers() {
    $('.stats_leaders').on('click', '.menu-category', function() {
      // if mobile view, scroll to first subcategory within the category, else show/hide subcategories
      if (window.matchMedia("(max-width: 768px)").matches) {
        $('html, body').animate({
          scrollTop: $("." + $(this).data("category") + "-group").offset().top - 110
        }, 700);
      } else {
        var category_list = $("#" + $(this).data("category") + "--submenu");
        $(this).toggleClass("active");
        $('.menu-category').not(this).removeClass("active");
        $('.results-menu--submenu').hide();
        if ($(this).hasClass("active")) {
          category_list.show();
        } else {
          category_list.hide();
        }
      }
    });

    $('.stats_leaders').on('click', '.menu-subcategory', function() {
      $('html, body').animate({
        scrollTop: $("#" + $(this).data("subcategory") + "-group").offset().top - 25
      }, 700);
    });

    $('.stats_leaders').on('click', '#back-to-top--menu', function() {
      $('html, body').animate({
        scrollTop: 0
      }, 400);
    });

  };
}

export let sidebar = new Sidebar();