export class Responsiveness {

  init() {
    this.mq = `(max-width: 768px)`;

    // get elements
    this.filtersForm = document.querySelector('#filters--form-elements');
    this.filtersHeading = document.querySelector('#filters--heading');
    this.mobileNavToggle = document.querySelector('#menu-main--mobile-menu-toggle');
    this.menuMain = document.querySelector('#menu-main');

    // check breakpoint initially
    if(window.matchMedia(this.mq).matches) {
      this.siteMode = 'mobile';
      this.initMobile();
    } else {
      this.siteMode = 'desktop';
      this.initDesktop();
    }

    // setup mobile navigation
    this.mobileNavToggle.addEventListener('click',(e)=>{
      if(e.currentTarget.getAttribute('aria-expanded')=='true') {
        e.currentTarget.setAttribute('aria-expanded','false');
        this.menuMain.classList.add('hidden');
      } else {
        e.currentTarget.setAttribute('aria-expanded','true');
        this.menuMain.classList.remove('hidden');
      }
    });
  };

  static checkBreakpoint(e, responsiveness_object) {
    // switch to mobile functionality
    if(e.matches) {
      responsiveness_object.initMobile();
    // switch to non-mobile functionality
    } else {
      responsiveness_object.initDesktop();
    }
  };

  initMobile() {
    // check if we're moving from desktop, if so undo desktop-only stuff
    //if(this.siteMode == 'desktop') {}

    // add hidden class to main navigation initially
    this.menuMain.classList.add('hidden');
    
    // remove visually hidden class from filters heading
    if (this.filtersHeading) {
      this.filtersHeading.classList.remove('visually-hidden');

      // change filters heading element to button and add settings
      this.filtersButton = document.createElement('button');
      this.filtersButton.setAttribute('type','button');
      this.filtersButton.setAttribute('aria-controls',this.filtersForm.id);
      this.filtersButton.innerText = this.filtersHeading.innerText;
      this.filtersHeading.innerText = "";
      this.filtersHeading.appendChild(this.filtersButton);
      this.filtersForm.setAttribute('role','region');

      // create svg arrow for filters button
      const ns = 'http://www.w3.org/2000/svg';
      let arrow = document.createElementNS(ns,'svg');
      arrow.setAttributeNS(ns,'aria-hidden','true');
      arrow.setAttributeNS(ns,'focusable','false');
      // arrow.setAttributeNS(ns,'version','1.1');
      arrow.setAttributeNS(ns,'viewBox','0 0 9.9 4.9');
      arrow.setAttributeNS(ns,'width','10');
      arrow.setAttributeNS(ns,'height','5');
      var path = document.createElementNS(ns,'path');
      path.setAttributeNS(null,'d','M9.9 0l-5 4.9L0 0');
      path.setAttributeNS(null,'fill','#191919');
      arrow.appendChild(path);
      this.filtersButton.appendChild(arrow);

      // add click behavior to filters button
      this.filtersButton.addEventListener('click',(e)=>{
        if(this.filtersButton.getAttribute('aria-expanded')=='true') {
          this.filtersForm.classList.add('hidden');
          this.filtersButton.setAttribute('aria-expanded','false');
        } else {
          this.filtersForm.classList.remove('hidden');
          this.filtersButton.setAttribute('aria-expanded','true');
        }
      });

      // collapse filters initially
      this.filtersForm.classList.add('hidden');
      this.filtersButton.setAttribute('aria-expanded','false');
    }

    // set site mode variable
    this.siteMode = 'mobile';
  };

  initDesktop() {
    // check if we're moving from mobile, if so then undo mobile-only things
    if(this.siteMode == 'mobile') {
      let headingText = this.filtersButton.innerText;
      this.filtersButton.removeAttribute('aria-expanded');
      this.filtersForm.classList.remove('hidden');
      this.filtersForm.removeAttribute('role');
      this.filtersButton.remove();
      this.filtersHeading.innerText = headingText;
      this.filtersHeading.classList.add('visually-hidden');
      this.menuMain.classList.remove('hidden');
    }

    //set site mode variable
    this.siteMode = 'desktop';
  };
}

export let responsiveness = new Responsiveness();